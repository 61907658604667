import {Search} from './components/Search.js'
import './App.css';

function App() {
  return (
      <Search/>
  );
}

export default App;
